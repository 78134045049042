
import { defineComponent } from 'vue';
import { IonItem, IonLabel, IonInput, IonButton } from '@ionic/vue';
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import getRandomToken from '@/utils/randomToken';
import { setCookie } from '@/utils/methods/cookieMethods';
import { setUserInfo } from '@/utils/methods/userMethods';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  data() {
    return {
      formError: [],
    };
  },
  methods: {
    submit: function(e: Event) {
      const formData = new FormData(e.currentTarget as HTMLFormElement);
      const inputEmail = formData.get('email');
      const inputPassword = formData.get('password');
      const inputUsername = formData.get('username');
      const inputFirstname = formData.get('firstname');
      const inputLastname = formData.get('lastname');
      const authToken = getRandomToken();
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          headers: {
            authorization: authToken,
          },
          action: 'register',
          email: inputEmail,
          password: inputPassword,
          username: inputUsername,
          firstname: inputFirstname,
          lastname: inputLastname,
        })
        .then(response => {
          if (!response.data.success || typeof response.data.userId !== 'number') {
            this.formError = response.data.errors;
            for (let i = 0; i < response.data.errors.length; i++) {
              console.error(response.data.errors[i]);
            }
          } else {
            const userId = response.data.userId;
            store.dispatch('initiateUserLogin', {
              userId: userId,
              authToken: authToken,
            });
            setCookie('sessionToken', authToken, 1);
            setUserInfo(authToken, userId);
            router.push('/profil');
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    submitKeyWatcher: function(e: KeyboardEvent) {
      if (e.key == 'Enter' && e !== null) {
        const submitButton = document.getElementById('ionic-submit');
        if (submitButton) {
          submitButton.click();
        }
      }
    },
  },
});
