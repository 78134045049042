
import { defineComponent } from 'vue';
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import RegistrationForm from '@/components/RegistrationForm.vue';
import store from '@/store';
// import router from '@/router';

export default defineComponent({
  name: 'Registrierung',
  components: { RegistrationForm, IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  computed: {
    loginState: () => {
      return store.getters.getLoginState;
    },
    currentUserInfo() {
      return store.getters.getUserInfo;
    },
  },
  // data() {
  //   return {
  //     router,
  //   };
  // },
});
